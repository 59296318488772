<template>
  <div class="ClientMain">
    <ClientHeader />
    <div class="Reference">
      <h2 class="NameOfServise">Сервис доставки еды</h2>
      <div class="ReferenceMainframe">
        <ChooseStationForm v-if="flag" />
      </div>
    </div>
    <div class="Footer">
      <ClientFooter />
    </div>
  </div>
</template>

<script>
import helper from '@/helpers/reference/reference.helper';
import ClientHeader from '@/components/food/ClientHeader';
import ClientFooter from '@/components/food/ClientFooter';
import ChooseStationForm from '@/components/food/ChooseStationForm';
import qrService from '@/services/QrService';
import stationService from '@/services/StationService';

import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      search: null,
      checkInterval: null,
      flag: false
    };
  },
  components: {
    ClientHeader,
    ClientFooter,
    ChooseStationForm
  },
  computed: {
    qr() {
      return this.$route.query.qr;
    },
    ...mapGetters('qr', ['qrData']),
    ...mapGetters('station', ['stationsList'])
  },
  methods: {
    declOfNum: helper.declOfNum,
    async checkQr() {
      if (this.qr !== undefined && this.qr !== null && this.qr.length > 0) {
        const result = await qrService.getQr(this.qr);
        localStorage.removeItem('stationToken');
        localStorage.removeItem('takeoutsArr');
        localStorage.removeItem('takeout');

        let stationToken = null;
        let takeoutToken = null;
        let cafeToken = null;

        const stationsResult = await stationService.getStationsList();
        let stFound;

        if (result.data.success === true) {
          if (result.data.qr.station !== null && result.data.qr.station.token.length > 0) {
            stationToken = result.data.qr.station.token;
            stFound = stationsResult.data.stations.find((station) => station.token === stationToken);
            if (stFound === undefined || stFound === null) {
              this.flag = true;
              console.log('Станция по данному QR недоступна'); // TODO
              return;
            }
            localStorage.setItem('stationToken', stationToken);
            localStorage.setItem('takeoutsArr', JSON.stringify(stFound.takeouts));
          }

          if (stFound.takeouts.length > 0 && result.data.qr.takeout !== null && result.data.qr.takeout.token.length > 0) {
            takeoutToken = result.data.qr.takeout.token;
            let toFound = stFound.takeouts.find((takeout) => takeout.token === takeoutToken);
            if (toFound !== undefined && toFound !== null) {
              localStorage.setItem('takeout', takeoutToken);
            }
          }

          if (result.data.qr.cafe !== null && result.data.qr.cafe.token.length > 0)  {
            cafeToken = result.data.qr.cafe.token;
            let cafeFound = stFound.cafes.find((cafe) => cafe.token === cafeToken);
            if (cafeFound === undefined || cafeFound === null) {
              localStorage.removeItem('takeout');
              this.flag = true;
              console.log('Кафе по данному QR недоступна'); // TODO
              return;
            } else if (takeoutToken !== null) {
              let toF = cafeFound.takeouts.find((takeout) => takeout.token === takeoutToken);
              if (toF !== undefined && toF !== null) {
                localStorage.setItem('takeout', takeoutToken);
              }
            }
          }

          if (cafeToken !== null) {
            this.$router.push({
              name: 'cafeMenu',
              params: { token: cafeToken }
            });
          } else {
            this.flag = true;
          }
        }
      } else {
        this.flag = true;
      }
    },
    updateStationsList() {
      this.fetchStationsList();
    },
    clearLocalStorage() {
      localStorage.removeItem('stationToken');
      localStorage.removeItem('takeoutsArr');
      localStorage.removeItem('takeout');
    },
    ...mapActions('qr', ['fetchQr']),
    ...mapActions('check', ['clientCheck']),
    ...mapActions('station', ['fetchStationsList'])
  },
  mounted() {
    //   this.clientCheck();
    //   this.checkInterval = setInterval(() => {
    //     try {
    //       this.clientCheck();
    //     } catch (ex) {
    //       console.log(ex);
    //     }
    //   }, 3600000);
    // },
    // beforeDestroy() {
    //   if (this.checkInterval !== null) {
    //     clearInterval(this.checkInterval);
    //   }
    this.clearLocalStorage();
    // this.updateStationsList();
    this.checkQr();
  }
};
</script>

<style scoped>
.ClientMain {
  background-color: rgb(255, 255, 255);
  font-family: RussianRail;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Reference {
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  background-color: white;
  padding-bottom: 30px;
  flex: 1 0 auto;
}
.ReferenceMainframe {
  //background-color: burlywood;
}
.NameOfServise {
  padding-top: 30px;
  text-align: center;
}
.TextMain {
  padding-left: 3%;
}
.text-black input {
  color: black !important;
}
.Footer {
  flex: 0 0 auto;
}
</style>
